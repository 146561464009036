const ALLOWED_FILTERS = [
  'free_breakfast', 'free_cancellation', 'guest_score', 'stars',
  'property_types', 'facilities', 'segments', 'bbox'
];

export default {
  methods: {
    getURLParam(param) {
      return this.readParamsFromURL()[param];
    },
    readParamsFromURL() {
      let urlParams = new URL(window.location).searchParams;
      return {
        ...Object.fromEntries(urlParams),
      };
    },
    updateURLParams(params) {

      // remove all filters params from URL
      let currentParams = {};
      new URL(window.location).searchParams.forEach((value, key) => {
        if(!ALLOWED_FILTERS.includes(key)) {
          currentParams[key] = value
        }
      });

      let urlParams = new URLSearchParams({
        ...currentParams,
        ...params,
      });
      history.replaceState(null, null, '?' + urlParams.toString());
    },
    removeURLParams(params) {

      let newParams = {};
      new URL(window.location).searchParams.forEach((value, key) => {
        if(!params.includes(key)) {
          newParams[key] = value
        }
      });
      let urlParams = new URLSearchParams({
        ...newParams,
      });
      history.replaceState(null, null, '?' + urlParams.toString())
    }
  }
}