var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { appear: "", name: "fade", duration: 100 } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showBody,
              expression: "showBody",
            },
          ],
          ref: "modal",
          staticClass: "vmodal-wrapper",
          style: _vm.cssVars,
          attrs: { tabindex: "-1" },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              $event.stopPropagation()
              return _vm.handleOverlayClick($event)
            },
            wheel: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.preventScroll($event)
            },
            touchmove: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.preventScroll($event)
            },
          },
        },
        [
          _c(
            "transition",
            {
              attrs: { appear: "", name: _vm.animationMode },
              on: { "after-leave": _vm.dismissModal },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showBody && _vm.bodyAnimationFinished,
                      expression: "showBody && bodyAnimationFinished",
                    },
                  ],
                  staticClass: "vmodal",
                  class: [
                    _vm.customClass,
                    _vm.closable ? "has-close-button" : "",
                  ],
                },
                [
                  _vm.closable && _vm.closable
                    ? _c(
                        "button",
                        {
                          staticClass: "vmodal__close",
                          attrs: { type: "button" },
                          on: { click: _vm.closeClicked },
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("×"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showHeader
                    ? _c(
                        "div",
                        { staticClass: "vmodal__header" },
                        [_vm._t("header")],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "vmodal__edge-fader-top",
                    class: { "has-close-button": _vm.closable },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "vmodal__body",
                      on: {
                        "&wheel": function ($event) {
                          return _vm.allowScroll($event)
                        },
                        "&touchmove": function ($event) {
                          return _vm.allowScroll($event)
                        },
                      },
                    },
                    [_vm._t("body")],
                    2
                  ),
                  _vm._v(" "),
                  _vm.showFooter
                    ? _c("div", { staticClass: "vmodal__footer row" }, [
                        _c(
                          "div",
                          { staticClass: "vmodal__footer__body" },
                          [
                            _vm._t("footer", [
                              _vm.closable
                                ? _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "vmodal__footer__close-button btn btn-primary rounded-sm transition-3d-hover font-size-16 font-bold",
                                        on: { click: _vm.closeClicked },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Close\n              "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }