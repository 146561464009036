import { render, staticRenderFns } from "./SubmenuModal.vue?vue&type=template&id=168c2d19&scoped=true&"
import script from "./SubmenuModal.vue?vue&type=script&lang=js&"
export * from "./SubmenuModal.vue?vue&type=script&lang=js&"
import style0 from "./SubmenuModal.vue?vue&type=style&index=0&id=168c2d19&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "168c2d19",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/code/mobytrip-hotelbeds/frontend/node_modules/vue-loader/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('168c2d19')) {
      api.createRecord('168c2d19', component.options)
    } else {
      api.reload('168c2d19', component.options)
    }
    module.hot.accept("./SubmenuModal.vue?vue&type=template&id=168c2d19&scoped=true&", function () {
      api.rerender('168c2d19', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/vue-apps/components/SubmenuModal.vue"
export default component.exports