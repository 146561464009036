// import Vue from 'vue';
// import VueI18n from "vue-i18n";
import { mapGetters } from "vuex";
import Modal from '../js/vue-apps/components/Modal.vue'
import Overlay from '../js/vue-apps/components/layout/Overlay.vue'
import SubmenuModal from '../js/vue-apps/components/SubmenuModal.vue'
import modalsMixin from '../js/vue-apps/mixins/modalsMixin';
import URLParamsMixin from '../js/vue-apps/mixins/URLParamsMixin';
import store from '../js/vue-apps/stores/store.js'

Vue.use(VueI18n);

new Vue({
    name: 'navbar',
    components: {
        Modal,
        Overlay,
        SubmenuModal,
    },
    delimiters: ["<%", "%>"],
    mixins: [modalsMixin, URLParamsMixin],
    store: store,
    data: {
        showMenuModal: false,
        showSupportModal: false,
        showUserModal: false,
        switchingToCurrency: undefined,
    },
    computed: {
        ...mapGetters({
            // 'hotelParams': 'pdp/hotelParams',
            // 'isValidRoomSearch': 'pdp/isValidRoomSearch',
            'currencies': 'user/availableCurrencies',
            'selectedCurrency': 'user/selectedCurrency',
        }),
        cssVars () {
            return {
              "--z_index": 100,
              "--z_index_body": 99,
            }
        },
        popularCurrencies() {
            return Object.values(this.currencies)
                .filter(currency => currency.popular)
                .sort((a, b) => a.code < b.code ? -1 : 1);
        },
        allCurrencies() {
            return Object.values(this.currencies)
            .filter(currency => !currency.popular)
            .sort((a, b) => a.code < b.code ? -1 : 1);
        }
    },
    beforeMount(){
        this.$store.dispatch('user/initCurrency', this.getURLParam('currency'));
    },
    methods: {
        handleCurrencyClick(currency) {
            let self = this;
            if (currency) {
                self.switchingToCurrency = currency;
                self.$store.dispatch('user/switchCurrency', currency).then(() => {
                    // console.log('Currency has been set and API call completed, executing additional code');
                }).catch(error => {
                    console.error('Error setting currency:', error);
                }).finally(() => {
                    // self.showCurrencyModal = false;
                    this.closeModal('currencies-overlay');
                    this.closeModal('currencies-modal');
                    self.switchingToCurrency = undefined;
                });
            } else {
                console.log('No currency data attribute found.');
                this.showCurrencyModal = false;
            }
        }
    },
}).$mount('#header');
