<template>
  <transition appear name="fade" :duration="100">
    <div
      v-show="showBody"
      class="vmodal-wrapper"
      tabindex="-1"
      @click.self.stop="handleOverlayClick"
      @wheel.stop.prevent="preventScroll" 
      @touchmove.stop.prevent="preventScroll"
      ref="modal"
      :style="cssVars"
    >
      <transition appear :name="animationMode" @after-leave="dismissModal">
        <div
            v-show="showBody && bodyAnimationFinished"
          class="vmodal"
          :class="[
            customClass,
            closable ? 'has-close-button' : '',
          ]"
        >
          <button
            v-if="closable && closable"
            class="vmodal__close"
            type="button"
            @click="closeClicked"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div
            v-if="showHeader"
            class="vmodal__header"
          >
            <slot name="header">
            </slot>
          </div>
          <div class="vmodal__edge-fader-top" :class="{'has-close-button': closable}"></div>
          <div
              @wheel.passive="allowScroll" @touchmove.passive="allowScroll" 
              class="vmodal__body">
            <slot name="body">
            </slot>
          </div>
          <div
            v-if="showFooter"
            class="vmodal__footer row"
          >
            <div class="vmodal__footer__body">
              <slot name="footer">
              <div class="d-flex" v-if="closable">
                <button
                  class="vmodal__footer__close-button btn btn-primary rounded-sm transition-3d-hover font-size-16 font-bold"
                  @click="closeClicked"
                >
                  Close
                </button>              
              </div>
            </slot>
            </div>
            
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    'z_index':{
      default: '1000000'
    },
    name: {
      type: String,
      default: 'unnamed_modal'
    },
    customClass: {
      type: String,
    },
    value: {
      default: false,
    },
    closable: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    priority: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      // scrollBarWidth: 0,
      windowHeight : undefined,
      windowWidth : undefined,
      bodyAnimationFinished: true,
      // showBody: false,
      id: undefined
    }
  },
  computed: {
    ...mapGetters({
      currentOpenModal: 'user/currentOpenModal',
    }),

    showBody() {
      try {
        return this.currentOpenModal.id == this.id
      } catch (error) {
        return false;
      }
    },
    animationMode(){
      if(this.windowWidth >= 500){
        return "popup"
      }
      return "slide-up"
    },
    cssVars () {
      return {
        "--vh": `${this.windowHeight}px`,
        "--z_index": this.z_index,
        "--z_index_fader": this.z_index + 1,
        "--backdrop_bg_color": this.backdrop_bg_color
      }
    }
  },
  watch: {
    showBody: function (newValue, _) {
      if (newValue) {
        // console.log("opened")
        submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state: 'modalOpened'})
      } else {
        // console.log("closed")
        submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state: 'modalClosed'})
      }
    },
    value() {
      if (this.value) {
          this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    preventScroll(event) {
      // This method is intentionally left blank as the scroll event 
      // is being handled (prevented) by the modifiers in the template
    },
    allowScroll(event) {
      // This allows the scroll event within the modal content without further propagation
      event.stopPropagation();
    },
    updateWindowDimensions() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    registerModal() {
      // Dispatch the action to register the modal
      // Include any other properties you need, like priority or type
      this.$store.dispatch('user/registerModal', { modalId: this.id, name: this.$props.name, priority: this.$props.priority });
      if(this.value){
        this.open();
      }
    },
    removeModal() {
      // Dispatch the action to remove the modal
      this.$store.dispatch('user/removeModal', this.id);
    },
    open() {
      // this.showBody = true;
      this.$emit('input', true);
      this.$store.dispatch('user/requestModalOpen', this.id);
      // this.$emit('open');
    },
    bounceAnimation(){
      this.$nextTick(() => {
          this.bodyAnimationFinished = false;
          setTimeout(() => {
            this.bodyAnimationFinished = true;
          }, 100);  
      });
    },
    closeClicked(){
      this.$emit('close-clicked');
      this.close();
    },
    close() {
      // this.showBody = false;
      // this.$emit('input', false);
      // this.$emit('close');
      // this.$emit('close-clicked');
      // this.dismissModal();
      this.$store.dispatch('user/closeModal', this.id);
      return true;
    },
    // modalOpened(){
    //   // if(this.showBody){
    //   // this.showBody = true;
    //   submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state:'modalOpened'})
    //   // }
    // },
    dismissModal(){
      if(!this.showBody){
        submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state:'modalClosed'})
        this.$emit('input', false);
        this.$emit('close');
      }
    },
    handleWindowFocus() {
      this.bounceAnimation();
    },
    handleWindowBlur() {
      this.bounceAnimation();
    },
    handleOverlayClick(){
      if (this.closable) {
        this.close();
      }else{
        this.bounceAnimation();
      }
    }
  },
  created() {
    let self = this;
    self.id = 'modal_' + uuidv4();
    self.registerModal();

    self.windowHeight = window.innerHeight;
    self.windowWidth = window.innerWidth;
    self.debouncedUpdateWindowDimensions = debounce(this.updateWindowDimensions, 100);
    window.addEventListener("resize", (e) => {
      self.debouncedUpdateWindowDimensions();
    });
    window.addEventListener("orientationchange", (e) => {
      self.debouncedUpdateWindowDimensions();
    });
  },
  mounted() {    
    window.addEventListener('focus', this.handleWindowFocus);
    window.addEventListener('blur', this.handleWindowBlur);
    // if(this.value == 'open'){
    //   submitLogDL_GA_plus_DB('modal_opened', this.$props.name, {})
    // }
  },
  beforeDestroy() {
    this.removeModal();
    // Remove event listeners to prevent memory leaks
    window.removeEventListener('focus', this.handleWindowFocus);
    window.removeEventListener('blur', this.handleWindowBlur);
  },
}
</script>

<style scoped lang="scss" >

@use "activeSite" as siteVars;
@use "sassRoot/breakpoints";
@use "sassRoot/animations";
@use 'sassRoot/mixins';


.vmodal-wrapper {
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: var(--vh);
  z-index: var(--z_index);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  backdrop-filter: blur(5px);
  @include breakpoints.breakpoint('biggerThanPhone') {
    align-items: center;
  }

}
.vmodal {
  background: siteVars.$body_bg_color;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  padding: 18px 18px 0px 18px;

  @include breakpoints.breakpoint('biggerThanPhone') {
    max-width: 80vw;
    min-height: 0vh;
    padding: 18px 18px 18px 18px;
    width: auto;
    border-radius: 20px;
  }

  &.has-close-button{
    padding: 50px 18px 0px 18px;
    @include breakpoints.breakpoint('biggerThanPhone') {
      padding: 50px 18px 18px 18px;
    }
  }
  max-height: 85vh;
  min-height: 50vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    border: none;
    background: transparent;
    font-size: 24px;
    line-height: 24px;
    color: siteVars.$modal_text_color;
    cursor: pointer;
    &:hover {
      color: siteVars.$warning_color;
    }
    &:focus {
      outline: none;
    }
  }
  &__edge-fader-top {
    position: absolute;
    top: 11px;
    left: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 15px;
    z-index: var(--z_index_fader);
  }
  &__edge-fader-top.has-close-button{
    top: 40px;
  }
  &__body {
    overflow-x: hidden;
    overflow: auto;
    position: relative;
    padding: 0 10px;
    //min-width: 40vw;
    width: 100%;
    min-height: 20vh;
    overscroll-behavior: contain;
    margin: 0 auto;
    flex-grow: 1;
  }
  &__header {
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    background: siteVars.$modal_header_bg;
    border-radius: 20px;
  }
  &__footer {
    border-top: 1px solid siteVars.$modal_header_bg;
    box-shadow: 0px -5px 26px -5px #C1C1C1;
    &__body {
      padding: 16px;
    }
    &__close-button {
      width: 100%;
      height: 58px;
      border-radius: 10px !important;

    }
  }

  &._coupon {
    padding: 40px;
    width: auto;
  }
  &._coupon &__edge-fader-top {
    display: none;
  }
  &._coupon &__body {
    min-width: auto;
    overflow: auto;
  }

  &._currencies {
    padding: 18px 18px 18px 18px;
  }
  &._currencies &__header {
    padding: 0 6px;
    background: transparent;
    color: #1E1C2A;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
  }
  &._currencies &__edge-fader-top {
    display: none;
  }
  &._currencies &__body {
    margin-top: 6px;
    @include mixins.scrollbar;
  }
}
</style>