var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideMenu,
          expression: "hideMenu",
        },
      ],
      ref: "menu",
      staticClass: "header-submenu",
    },
    [
      _c(
        "button",
        {
          staticClass: "header-submenu__button",
          class: { "header-submenu__button_open": _vm.isVisible },
          on: { click: _vm.toggleMenu },
        },
        [_vm._t("title", [_vm._v("\n            Menu\n        ")])],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", duration: { enter: 300, leave: 300 } } },
        [
          _vm.isVisible
            ? _c(
                "div",
                { staticClass: "header-submenu__dropdown" },
                [_vm._t("menu")],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }