<template>
    <div class="header-submenu" ref="menu" v-click-outside="hideMenu">
        <button
            class="header-submenu__button"
            :class="{ 'header-submenu__button_open': isVisible }"
            @click="toggleMenu"
        >
            <slot name="title">
                Menu
            </slot>
        </button>
        <transition name="fade" :duration="{ enter: 300, leave: 300 }">
            <div v-if="isVisible" class="header-submenu__dropdown">
                <slot name="menu"></slot>
            </div>
        </transition>
    </div>
</template>
<script>

import clickOutside from '../directives/click-outside';

export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    directives: {
        'click-outside': clickOutside
    },
    data() {
        return {
            $menu: null,
            isMobile: false,
        };
    },
    methods: {
        toggleMenu(){
            this.$emit('update:isVisible', !this.isVisible);
        },
        hideMenu() {
            this.$emit('update:isVisible', false);
        },
    },
}
</script>

<style scoped lang="scss">
@use "activeSite" as siteVars;
@use "sassRoot/breakpoints";
@use "sassRoot/animations";
</style>